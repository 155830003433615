import classnames from 'classnames/bind'
import { useRef } from 'react'
import { GlobalGridPreset } from '~/@types/grid-preset'

import {
  LinkProps,
  WrapperWithLink,
} from '@unlikelystudio/react-abstract-components'
import Slider from '@unlikelystudio/react-slider'

import Image, { ImageProps } from '~/components/UI/Image'
import PushTitleLink, {
  PushTitleLinkProps,
} from '~/components/UI/PushTitleLink'

import { useStyle } from '~/providers/StyleProvider'

import { EditoSliceProps } from '~/data/slices-data'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type PushSocialProps = EditoSliceProps & {
  className?: string
  pushTitle?: PushTitleLinkProps
  items?: {
    image: ImageProps
    link: LinkProps
  }[]
}

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 8 / 24 }, { ratio: 1 }]

function PushSocial({
  className,
  pushTitle,
  items,
  aboveTheFold,
}: PushSocialProps) {
  const customSliderRef = useRef()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  return (
    <div className={cx(css.PushSocial, className)} ref={customSliderRef}>
      <div className={gridStyle}>
        {pushTitle && (
          <PushTitleLink
            {...pushTitle}
            className={css.left}
            withDesktopPaddings
          />
        )}
        {items?.length > 0 && (
          <div className={css.right}>
            <Slider
              className={cx(css.slider)}
              snap
              customSliderRef={customSliderRef}>
              {items?.map((item, index) => (
                <WrapperWithLink key={`social_item_${index}`} {...item?.link}>
                  {item?.image && (
                    <Image
                      {...item?.image}
                      draggable={false}
                      layout="fill"
                      objectFit="cover"
                      sizesFromBreakpoints={IMAGE_SIZES}
                      ratio={css.ratio}
                      className={css.item}
                      asPlaceholder
                      priority={aboveTheFold}
                    />
                  )}
                </WrapperWithLink>
              ))}
            </Slider>
          </div>
        )}
      </div>
    </div>
  )
}

PushSocial.defaultProps = {}

export default PushSocial
