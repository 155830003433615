import classnames from 'classnames/bind'
import { GlobalGridPreset } from '~/@types/grid-preset'

import ProductCard, { ProductCardProps } from '~/components/UI/ProductCard'
import PushTitleLink, {
  PushTitleLinkProps,
} from '~/components/UI/PushTitleLink'

import { useStyle } from '~/providers/StyleProvider'

import { EditoSliceProps } from '~/data/slices-data'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ProductsGridProps = EditoSliceProps & {
  className?: string
  pushTitleLink?: PushTitleLinkProps
  items?: ProductCardProps[]
}

function ProductsGrid({
  className,
  pushTitleLink,
  items,
  aboveTheFold,
}: ProductsGridProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  return (
    <div className={cx(css.ProductsGrid, className)}>
      <div className={gridStyle}>
        {pushTitleLink && (
          <PushTitleLink
            {...pushTitleLink}
            className={css.left}
            withDesktopPaddings
          />
        )}
        <div className={css.right}>
          {items?.map((item, index) => (
            <ProductCard
              className={css.item}
              key={`product_card_${index}`}
              priority={aboveTheFold}
              {...item}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

ProductsGrid.defaultProps = {}

export default ProductsGrid
