import classnames from 'classnames/bind'
import React, { useState } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { Link, LinkProps } from '@unlikelystudio/react-abstract-components'

import { ImageProps } from '~/components/Abstracts/Image'
import Image from '~/components/UI/Image'
import InlineCta from '~/components/UI/InlineCta'
import SwitchImage from '~/components/UI/SwitchImage'

import { useStyle } from '~/providers/StyleProvider'

import { EditoSliceProps } from '~/data/slices-data'

import css from './styles.module.scss'

const cx = classnames.bind(css)

type CategoryItem = {
  link?: LinkProps
  linksTheme: typeof GlobalThemeColors.Black | typeof GlobalThemeColors.White
  image?: ImageProps
}

export type PushCategoriesProps = EditoSliceProps & {
  className?: string
  categories?: CategoryItem[]
}

const IMAGE_SIZES = [{ ratio: 1 }]

function PushCategories({
  className,
  categories,
  aboveTheFold,
}: PushCategoriesProps) {
  const [activeIndex, setActiveIndex] = useState(0)
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Cta16Grotesk,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div className={cx(css.PushCategories, className)}>
      {categories?.[activeIndex]?.image && (
        <SwitchImage
          {...categories?.[activeIndex]?.image}
          sizesFromBreakpoints={IMAGE_SIZES}
          layout="fill"
          objectFit="cover"
          objectPosition="center center"
          className={cx(css.image, css.hideOnSmallScreen)}
          asPlaceholder
          priority={false}
        />
      )}
      <div className={cx(css.grid, gridStyle)}>
        <div className={cx(css.categories, css.hideOnSmallScreen)}>
          {categories?.map((category, index) => {
            return (
              <InlineCta
                className={cx(css.cta, {
                  isActive: index === activeIndex,
                })}
                key={`category_item_${index}`}
                theme={categories?.[activeIndex]?.linksTheme}
                textPreset={GlobalTextPreset.Cta16Grotesk}
                lineProps={{ isVisible: false, initialLineState: 'none' }}
                onMouseEnter={() => {
                  if (index !== activeIndex) {
                    setActiveIndex(index)
                  }
                }}
                {...category?.link}
              />
            )
          })}
        </div>
      </div>
      <div className={cx(css.mobileCategories, css.hideOnLargeScreen)}>
        {categories?.map((category, index) => {
          return (
            <Link
              className={cx(css.card)}
              key={`category_item_mobile_${index}`}
              {...category?.link}>
              <Image
                sizesFromBreakpoints={IMAGE_SIZES}
                layout="fill"
                objectFit="cover"
                ratio={css.ratio}
                className={cx(css.mobileImage)}
                asPlaceholder
                priority={aboveTheFold}
                {...category?.image}
              />
              <span
                className={cx(
                  css.label,
                  labelStyle,
                  css?.[`${category?.linksTheme}-theme`],
                )}>
                {category?.link?.children}
              </span>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

PushCategories.defaultProps = {}

export default PushCategories
