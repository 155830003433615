import classnames from 'classnames/bind'
import React, { useEffect, useState } from 'react'

import Image, { ImageProps } from '~/components/UI/Image'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type SwitchImageProps = ImageProps & {
  className?: string
  imageClassName?: string
}

function SwitchImage({
  className,
  imageClassName,
  src,
  ...rest
}: SwitchImageProps) {
  const [currentSrc, setCurrentSrc] = useState<string>(src)
  const [nextSrc, setNextSrc] = useState<string>(null)

  useEffect(() => {
    setNextSrc(src)
  }, [src])

  const onAnimationEnd = () => {
    if (nextSrc) {
      setCurrentSrc(nextSrc)
      setNextSrc(null)
    }
  }

  return (
    <div
      className={cx(css.SwitchImage, className, {
        animate: nextSrc,
      })}>
      {nextSrc && (
        <div className={cx(css.image, css.next, imageClassName)}>
          <Image src={nextSrc} {...rest} />
        </div>
      )}

      <div
        className={cx(css.image, css.current, imageClassName)}
        onAnimationEnd={onAnimationEnd}>
        <Image src={currentSrc} {...rest} />
      </div>
    </div>
  )
}

SwitchImage.defaultProps = {}

export default SwitchImage
