import classnames from 'classnames/bind'
import React from 'react'
import { Card } from '~/@types/card'
import { GlobalTextPreset } from '~/@types/text-preset'

import { Link } from '@unlikelystudio/react-abstract-components'
import {
  DeviceType,
  useDeviceType,
  useIsHover,
} from '@unlikelystudio/react-hooks'

import { RichTextBlock } from '~/components/Abstracts/RichText'
import Image from '~/components/UI/Image'
import ImageHover from '~/components/UI/ImageHover'
import RichText from '~/components/UI/RichText'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type StoreCardProps = Card & {
  className?: string
  streetNumber?: string
  label?: RichTextBlock
}

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 8 / 24 }, { ratio: 1 }]

function StoreCard({
  className,
  link,
  image,
  imageHover,
  streetNumber,
  label,
  priority,
}: StoreCardProps) {
  const [isHovered, callbacks] = useIsHover()

  const device = useDeviceType()

  const streetNameStyle = useStyle({
    textPreset: GlobalTextPreset.Title44SangBleu,
  })

  const addressStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Grotesk,
  })

  const imageProps = {
    sizesFromBreakpoints: IMAGE_SIZES,
    ratio: css.ratio,
    draggable: false,
    priority,
  }

  const isHovering = device === DeviceType.mobile ? false : isHovered

  return (
    <Link className={cx(css.StoreCard, className)} {...link} {...callbacks}>
      <div className={css.cardContainer}>
        <div className={css.cardImages}>
          {image && (
            <Image
              className={cx(css.image)}
              asPlaceholder
              layout="fill"
              objectFit="cover"
              {...imageProps}
              {...image}
            />
          )}
          {imageHover && (
            <ImageHover
              isHovering={isHovering}
              className={css.hoverImage}
              layout="fill"
              objectFit="cover"
              {...imageProps}
              {...imageHover}
            />
          )}
        </div>
        <div className={css.cardBottom}>
          {streetNumber && (
            <span className={cx(css.streetNumber, streetNameStyle)}>
              {streetNumber}
            </span>
          )}
          {label && (
            <RichText
              render={label}
              className={cx(css.address, addressStyle)}
            />
          )}
        </div>
      </div>
    </Link>
  )
}

StoreCard.defaultProps = {}

export default StoreCard
