import classnames from 'classnames/bind'
import { useRef } from 'react'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'

import Slider, { useSliderState } from '@unlikelystudio/react-slider'

import { ArrowRightIcon } from '~/components/UI/Icons'
import StoreCard, { StoreCardProps } from '~/components/UI/StoreCard'
import TitleVariable, {
  TitleVariableProps,
} from '~/components/UI/TitleVariable'

import { useStyle } from '~/providers/StyleProvider'

import { EditoSliceProps } from '~/data/slices-data'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type PushStoresProps = EditoSliceProps & {
  className?: string
  title?: TitleVariableProps
  stores?: StoreCardProps[]
}

function PushStores({
  className,
  title,
  stores,
  aboveTheFold,
}: PushStoresProps) {
  const customSliderRef = useRef()
  const [
    {
      prevSlide,
      nextSlide,
      enableDrag,
      slideIndex,
      defaultBounds,
      maxSlideIndex,
    },
    setSliderState,
  ] = useSliderState()

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const navigationStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12Grotesk,
  })
  const hasPrevButton = enableDrag && slideIndex !== 0
  const hasNextButton =
    slideIndex !== maxSlideIndex &&
    enableDrag &&
    slideIndex !== stores?.length - 1
  return (
    <div className={cx(css.PushStores, className)} ref={customSliderRef}>
      <div className={gridStyle}>
        {title && (
          <div
            className={cx(css.top, {
              withControls: hasPrevButton || hasNextButton,
            })}>
            {title && <TitleVariable {...title} className={css.title} />}
          </div>
        )}
        {stores?.length > 0 && (
          <div className={css.body}>
            {(hasPrevButton || hasNextButton) && (
              <div className={cx(css.sliderControls)}>
                {hasPrevButton ? (
                  <div className={cx(css.prev)} onClick={prevSlide}>
                    <ArrowRightIcon className={cx(css.icon)} />
                  </div>
                ) : (
                  <div className={cx(css.empty)} />
                )}
                <div className={cx(css.slideIndicator, navigationStyle)}>
                  <span className={cx(css.activeIndex)}>{slideIndex + 1}</span>
                  <span>&nbsp;/ {maxSlideIndex + 1}</span>
                </div>
                {hasNextButton ? (
                  <div className={cx(css.next)} onClick={nextSlide}>
                    <ArrowRightIcon className={cx(css.icon)} />
                  </div>
                ) : (
                  <div className={cx(css.empty)} />
                )}
              </div>
            )}
            <Slider
              className={cx(css.slider)}
              snap
              setSliderState={setSliderState}
              autoBlockSlideIndexChange={true}
              customSliderRef={customSliderRef}>
              {stores?.map((item, key) => (
                <StoreCard
                  key={`store_card_${key}`}
                  className={css.item}
                  priority={aboveTheFold}
                  {...item}
                />
              ))}
            </Slider>
          </div>
        )}
      </div>
    </div>
  )
}

PushStores.defaultProps = {}

export default PushStores
